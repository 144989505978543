<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>

            <el-breadcrumb-item>商品信息</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="2" class="add-btn-container">
          <el-button v-if="itype == 1" type="text" @click="toCreate">添加</el-button>
        </el-col>
      </el-row>
    </div>

    <el-container>
      <!--分类-->
      <el-aside width="150px">
        <el-tree :data="treedata" ref="tree" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </el-aside>
      <el-main>

        <!-- 列表内容 -->
        <div class="main" v-if="itype == 1">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
              <el-input v-model="name" placeholder="请输入商品名称"></el-input>
            </el-form-item>

            <el-form-item label="状态">
              <el-radio-group v-model="states">
                <el-radio :label=-1>全部</el-radio>
                <el-radio :label=1>启用</el-radio>
                <el-radio :label=0>禁用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getList">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="listData" border="" style="width: 100%">

            <el-table-column prop="id" label="ID" align="center" width="95"></el-table-column>

            <el-table-column prop="sorder" width="95" label="序号">
              <template slot-scope="scope">
                <el-input v-model="scope.row.sorder" @change="updatesort(scope.row)"></el-input>
              </template>
            </el-table-column>

            <el-table-column label="商品略图" width="110" align="center">
              <template slot-scope="scope">
                <el-image :src="scope.row.homeImg" alt="图片">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称"></el-table-column>

            <el-table-column prop="shopname" label="所属商家">

            </el-table-column>
            <el-table-column prop="pricedoor" label="市场价"></el-table-column>
            <el-table-column prop="price" label="商城价"></el-table-column>
            <el-table-column prop="stock" label="库存"></el-table-column>
            <el-table-column prop="addtime" label="添加日期"></el-table-column>
            <el-table-column prop="states" label="状态" width="60">
              <template slot-scope="scope"> {{ ['下架', '上架'][scope.row.states] }} </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="213">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="setStates(scope.row.id, 0, scope.row)"
                  v-if="scope.row.states == 1">下架</el-button>
                <el-button type="success" size="mini" @click="setStates(scope.row.id, 1, scope.row)" v-else>上架
                </el-button>
                <el-button size="mini" @click="toEdit(scope.row.id)">编辑</el-button>
                <el-button size="mini" type="danger" @click="onDelete(scope.row.id)">删除</el-button>

              </template>
            </el-table-column>
          </el-table>

        </div>
        <!-- 分页 -->
        <div class="pagination" v-if="itype == 1">
          <el-pagination background="" layout="prev, pager, next" :page-size="10" :current-page="pageIndex"
            :total="pageTotal" @current-change="onCurrentChange" />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getListRequest, setSortRequest, setStatesRequest, delRequest } from '@/api/zrproduct'
import { getSelectRequest } from '@/api/zrproducttype'
import { getSelectshopRequest } from '@/api/zrshop'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ArticleList',
  components: {},

  data() {
    return {
      listData: [],
      classify: [],
      name: '',
      typeId: 0,
      pageIndex: 1,
      pageTotal: 0,
      treedata: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      itype: 1,
      shopdata: [],
      states: -1,
    }
  },

  watch: {
    $route: {
      handler(to, from) {
        this.typeId = to.query.typeId || 0

        this.onCurrentChange(1)
      },
      immediate: true,
    },
  },

  methods: {
    getSelectshop() {
      getSelectshopRequest().then((res) => {
        this.shopdata = res.data
      })
    },
    getList() {
      getListRequest({
        name: this.name,
        states: this.states,
        typeId: this.typeId,
        pageNum: this.pageIndex,
        pageSize: 10,
        isintegral: 0,
      }).then((res) => {
        this.listData = res.data.data
        this.pageTotal = res.data.count
      })
    },
    getSelect() {
      getSelectRequest().then((res) => {
        this.treedata = res.data
      })
    },
    gettypedetail() {
      getdatilRequest({
        Id: this.typeId,
      }).then((res) => {
        console.log(res)
        this.form = res.data
      })
    },
    changeClassify(e) {
      this.typeId = e[e.length - 1]
    },
    handleNodeClick(data) {
      this.$router.push({
        path: '/zrproduct/list',
        query: { itype: 1, typeId: data.id },
      })
    },

    updatesort(row) {
      setSortRequest({
        Id: row.id,
        Sorder: row.sorder,
      }).then((res) => { })
    },
    setStates(id, states, row) {
      setStatesRequest({
        Id: id,
        States: states,
      }).then((res) => {
        row.states = states
      })
    },
    toCreate() {
      this.$router.push({
        path: '/zrproduct/edit',
        query: { typeId: this.typeId, id: 0 },
      })
    },

    toEdit(id) {
      this.$router.push({
        path: '/zrproduct/edit',
        query: { typeId: this.typeId, id: id },
      })
    },
    goitem(id) {
      this.$router.push({
        path: '/workinfomake/list',
        query: { workid: id },
      })
    },

    onDelete(id) {
      this.$confirm('确认删除该记录吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 调用删除接口
          return delRequest({ id })
        })
        .then(() => {
          this.$message({
            type: 'success',
            message: `删除成功！`,
          })
          this.getList()
        })
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
  },

  created() {
    this.getSelectshop()
    this.getSelect()
    this.getList()
  },

  validations: {
    form: {
      name: {
        required,
      },
      pId: {
        required,
      },
      describe: {
        required,
      },
    },
  },
}
</script> 

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>